import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

/**
 * Small wrapper around try-catch block, avoid call an error if request has been cancelled.
 * @typedef apiRequest
 * @property {Function} catch  {@link}
 *
 * @param {Promise|Function} success You put your try code on this function.
 * @returns apiRequest
 */
export default function apiRequest(success) {
  return {
    /**
     * @param {Promise|Function} failure This is a Promise or a async function.
     * @returns {callbackReturn:any, isError:boolean, isCancel: boolean}
     * @description You put your catch code on this function. and then run try
     * and catch code for you.
     */
    async catch(failure) {
      try {
        return {
          callbackReturn: await success(),
          isError: false,
          isCancel: false,
        };
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          return {
            callbackReturn: typeof failure === 'function' ? await failure(error) : undefined,
            isError: true,
            isCancel: false,
          };
        }
        return { isError: true, isCancel: true };
      }
    },
  };
}
