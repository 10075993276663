<template>
  <publisher-tabs />
</template>

<script>
import PublisherTabs from '@/components/organisms/modules/ecommerce/publisher/PublisherTabs.vue';

export default {
  name: 'PublisherEdit',
  components: {
    PublisherTabs,
  },
};
</script>

<style></style>
