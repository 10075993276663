import basicInfo from '@/router/private/modules/ecommerce/supply/publisher/edit/basicInfo';
import additionalInfo from '@/router/private/modules/ecommerce/supply/publisher/edit/additionalInfo';
import { default as users } from '@/router/private/modules/ecommerce/admin/users/list';
import { default as newBasicInfo } from '@/router/private/modules/ecommerce/supply/publisher/create/basicInfo';

const tabs = {
  new: {
    name: 'Basic Info',
    route: newBasicInfo,
  },
  edit: {
    name: 'Basic Info',
    route: basicInfo,
  },
  users: {
    name: 'Users',
    route: users,
  },
  additionalInfo: {
    name: 'Additional Info',
    route: additionalInfo,
  },
};

const tabsCreate = () => [tabs.new, tabs.users];
const tabsEdit = () => [tabs.edit, tabs.users];

export default Object.freeze({ tabs, tabsCreate, tabsEdit });
