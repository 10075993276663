<template>
  <div>
    <meta-actions-header class="mb-12" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1 text-gray-700"
          ><span>: </span><span v-if="publisher.id" class="text-orange-500">{{ publisher.name }}</span>
        </breadcrumb>
      </template>
    </meta-actions-header>
    <tabs :tabs-data="tabs" :base-publisher="publisher" :clients="clients" :loading-publisher="loadingPublisher"></tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import Tabs from '@/components/atoms/Tabs';
import CONFIG from './config';
import Tab from '@/model/shared/Tab';
import apiRequest from '@/utils/apiRequest';
import getPublisherById from '@/services/modules/ecommerce/publisher/getPublisherById';
import { Toast } from '@/model/shared/Toast';
import Publisher from '@/entities/ecommerce/Publisher';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getClients } from '@/services/modules/Core/client';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { USER } from '@/store/modules/auth/keys';

export default {
  name: 'PublisherTabs',
  components: {
    MetaActionsHeader,
    Breadcrumb,
    Tabs,
  },
  data: () => ({
    isLoading: false,
    loadingPublisher: false,
    publisher: null,
    clients: null,
    tabs: [],
  }),
  computed: {
    ...mapGetters({
      user: USER,
    }),
    publisherId() {
      return this.$route.params.publisherId;
    },
    isEditing() {
      return !!this.$route.params.publisherId;
    },
    contextUserRole() {
      return this.user?.contextRoles.find(c => c.context === CONTEXTS.ECOMMERCE.id).role;
    },
    showAdditionalInfoTab() {
      return [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.SUPER_ADVERTISER.id].includes(this.contextUserRole);
    },
  },
  async created() {
    this.isLoading = true;
    this.configTabs();
    await Promise.all([this.getPublisher(), this.getClients()]);
    this.isLoading = false;
  },
  methods: {
    configTabs() {
      const tabs = this.isEditing ? CONFIG.tabsEdit() : CONFIG.tabsCreate();
      if (this.isEditing && this.showAdditionalInfoTab) {
        tabs.push(CONFIG.tabs.additionalInfo);
      }
      this.tabs = tabs.map(({ name, route }) => {
        const tab = new Tab(name, route);
        if (name === 'Users') {
          const params = new QueryParamsBuilder();
          params.addFilter('contextRole.role', 'PUBLISHER');
          params.addFilter('userPublisherLink.publisherId', this.publisherId);
          const query = params.buildWithoutPage();
          tab.route.query = query;
        }
        return tab;
      });
    },
    async getPublisher() {
      if (this.isEditing) {
        this.loadingPublisher = true;
        await apiRequest(async () => {
          const { data } = await getPublisherById(this.publisherId);
          this.publisher = data;
        }).catch(error => {
          this.createToast(Toast.error(`Failed to load the publisher`, error.message));
        });
        this.loadingPublisher = false;
      } else {
        this.publisher = new Publisher();
      }
    },
    async getClients() {
      await apiRequest(async () => {
        const params = new QueryParamsBuilder(1, 1000);
        params.addFilter('type', CONTEXTS.ECOMMERCE.id);
        const { data } = await getClients(params);
        this.clients = data;
      }).catch(error => {
        this.createToast(Toast.error(`Failed to load clients`, error.message));
      });
    },
  },
};
</script>
